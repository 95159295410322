@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Satoshi-Regular.otf");
}

@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 500;
  src: url("./assets//fonts/Satoshi-Medium.otf");
}

@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Satoshi-Bold.otf");
}
@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Satoshi-Light.otf");
}
@font-face {
  font-family: Satoshi;
  font-style: italic;
  src: url("./assets/fonts/Satoshi-Italic.otf");
}
@font-face {
  font-family: Satoshi;
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/Satoshi-Black.otf");
}
