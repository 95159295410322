@import url("normalize.css");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("typography.css");

body {
  font-family: Satoshi;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
small,
p {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}
