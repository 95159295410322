:root {
    --main: #0b2239;
}

@import url("typography.css");

.satoshi {
    font-family: Satoshi !important;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ebf1ff;
    border-radius: 10px;
}

/*p {*/
/*    padding-bottom: 0px !important;*/
/*    margin-bottom: 0px !important;*/
/*}*/

::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--main);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #9cd5ff;
    /* background: var(--color5); */
}

.bolder {
    font-family: SatoshiB;
}

.loginbg {
    background: linear-gradient(180deg, #021c2f 0%, #0592f6 84.79%, #021c2f 100%);
}

.myBorder {
    border: 0.4px solid rgba(255, 255, 255, 0.2);
}

.custom-list {
    list-style-type: disc;
    color: #1c1c1c;
}

.noScroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

* {
    text-decoration: none !important;
}

.style-module_simpleStarRating__nWUxf svg {
    display: inline !important;
}

.banner {
    background-image: url("./assets/mainbg.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
}

.smallText {
    @apply tw-text-sm tw-text-main satoshi tw-font-medium;
}

.tableText {
    @apply tw-text-sm tw-text-main satoshi tw-p-3 tw-font-normal;

}

.truncate {
    display: -webkit-box;
    /*-webkit-box-orient: vertical;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*-webkit-line-clamp: 1;*/
    /*max-width: 6rem;*/
}

pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
